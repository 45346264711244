import React from "react";

const InputRadioBox = ({ name, options, register, isDark = false }) => {
  const labelStyle = isDark
    ? "text-sm text-white font-normal lg:text-darkGray lg:font-medium"
    : "text-sm text-darkGray font-normal lg:text-darkGray lg:font-medium";

  return options?.map((option, index) => (
    <div
      key={index}
      className="col-span-1 flex gap-x-4 items-center"
      {...register(name)}
    >
      <input
        type="radio"
        id={option.title}
        name={name}
        value={option.title}
        required
      />
      <label htmlFor={option.title} className={labelStyle}>
        {option.title}
      </label>
    </div>
  ));
};

export default InputRadioBox;

// <label
//   className="col-span-1 flex gap-x-4 items-center"
//   onClick={() => onChange(title)}
// >
//   <input type="radio" readOnly checked={checked} />
//   <span className={spanStyle}>{title}</span>
// </label>
