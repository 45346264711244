import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <section>{children}</section>
      <Footer />
    </main>
  );
};

export default Layout;
