const servicesDetailsData = [
  {
    id: 1,
    title: "New Builds",
    shortTitle: "New builds your home or commercial propoerty?",
    help: "We can help",
    descriptionOne:
      "Finding the right home renovation electrician and choosing the right electrical solutions for your renovation project can be daunting. We have the knowledge and experience to help support you to make the best decisions for your needs, delivering within your timeframe and budget to give you the best possible outcome.",
    descriptionTwo:
      "Ace-Tech has extensive knowledge in renovations and rewiring, and can help you find solutions to keep in line with the character of your property.",
    descriptionThree:
      "From simple to complex home renovations, Totally Amped Electrical service the wider Auckland region, delivering excellent results and award winning outcomes to our clients.",
    icon: "/images/services/new-build/white-hover.svg",
    coverImage: "/images/details/new-build.png",
  },
  {
    id: 2,
    title: "Rennovations",
    shortTitle: "Rennovations your home or commercial propoerty?",
    help: "We can help",
    descriptionOne:
      "Finding the right home renovation electrician and choosing the right electrical solutions for your renovation project can be daunting. We have the knowledge and experience to help support you to make the best decisions for your needs, delivering within your timeframe and budget to give you the best possible outcome.",
    descriptionTwo:
      "Ace-Tech has extensive knowledge in renovations and rewiring, and can help you find solutions to keep in line with the character of your property.",
    descriptionThree:
      "From simple to complex home renovations, Totally Amped Electrical service the wider Auckland region, delivering excellent results and award winning outcomes to our clients.",
    icon: "/images/services/new-build/white-hover.svg",
    coverImage: "/images/details/new-build.png",
  },
  {
    id: 3,
    title: "Repairs",
    shortTitle: "Repairs your home or commercial propoerty?",
    help: "We can help",
    descriptionOne:
      "Finding the right home renovation electrician and choosing the right electrical solutions for your renovation project can be daunting. We have the knowledge and experience to help support you to make the best decisions for your needs, delivering within your timeframe and budget to give you the best possible outcome.",
    descriptionTwo:
      "Ace-Tech has extensive knowledge in renovations and rewiring, and can help you find solutions to keep in line with the character of your property.",
    descriptionThree:
      "From simple to complex home renovations, Totally Amped Electrical service the wider Auckland region, delivering excellent results and award winning outcomes to our clients.",
    icon: "/images/services/new-build/white-hover.svg",
    coverImage: "/images/details/new-build.png",
  },
  {
    id: 4,
    title: "Maintenance",
    shortTitle: "Maintenance your home or commercial propoerty?",
    help: "We can help",
    descriptionOne:
      "Finding the right home renovation electrician and choosing the right electrical solutions for your renovation project can be daunting. We have the knowledge and experience to help support you to make the best decisions for your needs, delivering within your timeframe and budget to give you the best possible outcome.",
    descriptionTwo:
      "Ace-Tech has extensive knowledge in renovations and rewiring, and can help you find solutions to keep in line with the character of your property.",
    descriptionThree:
      "From simple to complex home renovations, Totally Amped Electrical service the wider Auckland region, delivering excellent results and award winning outcomes to our clients.",
    icon: "/images/services/new-build/white-hover.svg",
    coverImage: "/images/details/new-build.png",
  },
  {
    id: 5,
    title: "Equipments",
    shortTitle: "Equipments your home or commercial propoerty?",
    help: "We can help",
    descriptionOne:
      "Finding the right home renovation electrician and choosing the right electrical solutions for your renovation project can be daunting. We have the knowledge and experience to help support you to make the best decisions for your needs, delivering within your timeframe and budget to give you the best possible outcome.",
    descriptionTwo:
      "Ace-Tech has extensive knowledge in renovations and rewiring, and can help you find solutions to keep in line with the character of your property.",
    descriptionThree:
      "From simple to complex home renovations, Totally Amped Electrical service the wider Auckland region, delivering excellent results and award winning outcomes to our clients.",
    icon: "/images/services/new-build/white-hover.svg",
    coverImage: "/images/details/new-build.png",
  },
  {
    id: 6,
    title: "Consultancy",
    shortTitle: "Consultancy your home or commercial propoerty?",
    help: "We can help",
    descriptionOne:
      "Finding the right home renovation electrician and choosing the right electrical solutions for your renovation project can be daunting. We have the knowledge and experience to help support you to make the best decisions for your needs, delivering within your timeframe and budget to give you the best possible outcome.",
    descriptionTwo:
      "Ace-Tech has extensive knowledge in renovations and rewiring, and can help you find solutions to keep in line with the character of your property.",
    descriptionThree:
      "From simple to complex home renovations, Totally Amped Electrical service the wider Auckland region, delivering excellent results and award winning outcomes to our clients.",
    icon: "/images/services/new-build/white-hover.svg",
    coverImage: "/images/details/new-build.png",
  },
];

export default servicesDetailsData;
