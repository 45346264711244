import React from "react";

const Input = ({ name, type, label, placeholder, register }) => {
  return (
    <div className="mt-2 grid grid-cols-9 gap-x-5">
      <label className="col-span-2 text-sm text-lightGray-200 font-normal lg:text-darkGray lg:font-medium">
        {label}
      </label>
      <input
        type={type}
        className="col-span-7 py-0 px-1 w-full block border border-lightGray-200 rounded-r-full text-sm text-darkGray font-normal focus:outline-none lg:text-darkGray lg:font-medium"
        placeholder={placeholder}
        required
        {...register(name)}
      />
    </div>
  );
};

export default Input;
