const navbarData = [
  {
    title: "Home",
    link: "/#home",
  },
  {
    title: "Our Services",
    link: "/#services",
  },
  {
    title: "About Us",
    link: "/#about",
  },
  {
    title: "Testimonials",
    link: "/#testimonial",
  },
  {
    title: "Contact ",
    link: "/#contact",
  },
];

export default navbarData;
