import React from "react";

const affordableData = [
  "Highest quality materials",
  "fixed price quotes",
  "Professional project management",
  "Fully insured registered electronics",
  "Efficient, helpful, high-energy team",
  "Cost effective solutions",
];

const AffordablePrice = () => {
  return (
    <section className="mt-5 lg:mt-20 w-full py-4 bg-lightGray-200 lg:bg-slate-300 lg:bg-opacity-70">
      <div className="px-7">
        <div className="bg-white lg:bg-inherit w-full h-full rounded-10px px-8 py-8">
          <h2 className="text-[16px] font-extrabold text-darkGray font-popins">
            WE DO THE JOB RIGHT &<br /> AT AN AFFORDABLE PRICE
          </h2>
          <ul className="mt-5 ml-5 mb-6">
            {affordableData.map((item) => (
              <li
                key={item}
                className="mb-1 capitalize list-disc text-sm text-lightGray-200 lg:text-black font-normal"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AffordablePrice;
