import React from "react";

const AboutUs = () => {
  return (
    <section id="about" className="mt-5 lg:mt-12">
      <h1 className="text-lg font-extrabold text-darkGray text-center lg:text-3xl">
        ABOUT US
      </h1>
      <div className="mt-5 bg-gray-900 py-4 lg:mt-12">
        <div className="body-x-padding">
          <div className=" bg-lightBlue rounded-10px text-center p-5 lg:p-20 lg:text-left">
            <p>
              Ace-Tech Electrical Limited (issued an NZ business number of
              9429041215807) was incorporated on 05 May 2014. 1 address is
              currently in use by the company: 552 Swanson Road, Ranui,
              Auckland, 0612 (type: physical, registered). 23 Peter Buck Road,
              New Windsor, Auckland had been their registered address, until 13
              Nov 2020. 101 shares are allocated to 2 shareholders who belong to
              2 shareholder groups. The first group is composed of 1 entity and
              holds 45 shares (44.55% of shares), namely: Lubna Iasmin (an
              individual) located at Ranui, Auckland postcode 0612.
            </p>
            <p className="mt-3">
              As far as the second group is concerned, a total of 1 shareholder
              holds 55.45% of all shares (56 shares); it includes Mohammed
              Sharif (an individual) - located at Ranui, Auckland. "E323220
              Electrical services" (ANZSIC E323220) is the classification the
              Australian Bureau of Statistics issued to Ace-Tech Electrical
              Limited. The Businesscheck information was last updated on 09 Aug
              2022.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
