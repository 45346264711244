import React from "react";

const Hero = ({ service }) => {
  const { title, descriptionOne, icon, coverImage } = service || {};

  return (
    <div
      className="body-x-padding py-10 h-[528px] w-full bg-no-repeat bg-center bg-cover hidden lg:block"
      style={{ backgroundImage: "url('/images/bg/home-cover.png')" }}
    >
      <div className="grid grid-cols-12 pl-8 py-8 bg-white rounded-10px opacity-70">
        <div className="col-span-5 pr-10">
          <div className="h-[99px] w-[97px] bg-midBlue rounded-10px flex items-center justify-center">
            <img src={icon} alt="" className="w-[44px] h-[50px]" />
          </div>
          <h1 className="my-3 text-lg font-extrabold text-darkGray capitalize">
            {title}
          </h1>
          <p className="text-sm text-darkGray font-medium">{descriptionOne}</p>
        </div>
        <div className="col-span-7 w-full">
          <img src={coverImage} alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
