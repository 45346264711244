import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section id="contact" className="pb-2 bg-darkGray text-white">
      <div className="py-5 body-x-padding grid grid-cols-1 gap-y-8 lg:grid-cols-2">
        <div className="col-span-1 text-center lg:text-left">
          <h6 className="text-lg font-medium">More about us:</h6>
          <li className="list-none">
            <Link to="/" className="text-[12px] hover:underline">
              Terms and conditions
            </Link>
          </li>
          <li className="list-none">
            <Link to="/" className="text-[12px] hover:underline">
              Disclaimer
            </Link>
          </li>
          <li className="list-none">
            <Link to="/" className="text-[12px] hover:underline">
              Privacy & policy
            </Link>
          </li>
          <li className="list-none">
            <Link to="/" className="text-[12px] hover:underline">
              FAQs
            </Link>
          </li>
        </div>
        <div className="col-span-1 text-center lg:text-right">
          <h6 className="text-lg font-medium">Contact us:</h6>
          <a
            href="tel:+64212888700"
            className="text-[12px] text-gray-400 block"
          >
            Phone
            <span className="text-white"> +64 21 2888 700</span>
          </a>
          <a
            href="mailto:Info@acetechelectrical.co.nz"
            className="text-[12px] text-gray-400 block"
          >
            Email
            <span className="text-white"> Info@acetechelectrical.co.nz</span>
          </a>
        </div>
      </div>
      {/* <p className="text-center text-gray-400 text-[10px]">
        We are available on
      </p> */}
    </section>
  );
};

export default Footer;
