import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import Input from "../../components/form/Input";
import InputRadioBox from "../../components/form/InputRadioBox";
import servicesData from "../../data/servicesData";

const formTopData = [
  { id: 1, title: "Residential" },
  { id: 2, title: "Commercial" },
];

const ServicesForm = () => {
  const form = useRef();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = () => {
    emailjs
      .sendForm(
        "service_ite5ts9",
        "template_nq7bom9",
        form.current,
        "mYQ4uSApCMU2rdAiF"
      )
      .then(
        (result) => {
          reset();
          console.log(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <section className="mt-5 lg:mt-0">
      <div className="hidden pl-[27px] pt-1 pb-1.5 w-full relative rounded-full border border-darkGray lg:block">
        <span className="uppercase text-[12px] text-black font-medium">
          request for a service
        </span>
        <button
          type="button"
          className="px-5 py-[5px] absolute top-0 right-0 border border-lightGray-200  bg-lightGray-200 text-white rounded-r-full cursor-default"
        >
          <img className="w-5 h-[22px]" src="/icon/down.svg" alt="" />
        </button>
      </div>
      <form ref={form} className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="px-2 flex justify-between items-center lg:bg-[#E9F8FF] lg:py-2 lg:rounded-md">
          <InputRadioBox
            name="topTitle"
            options={formTopData}
            register={register}
          />
        </div>

        <div className="mt-2 p-2 w-full rounded-10px bg-white lg:bg-lightGray-200">
          <div className="px-5 py-4 w-full bg-darkGray rounded-10px grid gap-4 grid-cols-2 text-white lg:bg-lightGray-300">
            <InputRadioBox
              name="service"
              options={servicesData}
              register={register}
              isDark={true}
            />
          </div>

          <div>
            <p className="my-2 px-6 text-sm font-normal text-darkGray lg:text-white">
              Additional Information:
            </p>
            <div className="px-5 py-4 w-full bg-darkGray rounded-10px lg:bg-lightGray-300">
              <textarea
                rows="5"
                className="w-full p-1 text-sm bg-darkGray font-normal text-white focus:outline-0 lg:bg-lightGray-300 lg:text-darkGray lg:font-medium"
                placeholder="Type here a brief of the job"
                required
                {...register("additionalInformation")}
              />
            </div>
          </div>
          <div>
            <p className="my-2 px-6 text-sm font-normal text-darkGray lg:text-white">
              Your contact details:
            </p>
            <div className="px-5 py-4 w-full bg-darkGray rounded-10px lg:bg-lightGray-300">
              <Input
                name="name"
                type="text"
                label="Name"
                placeholder=""
                register={register}
              />
              <Input
                name="email"
                type="email"
                label="Email"
                placeholder=""
                register={register}
              />
              <Input
                name="phone"
                type="text"
                label="Phone"
                placeholder=""
                register={register}
              />
              <Input
                name="location"
                type="text"
                label="Location"
                placeholder="suburb & city"
                register={register}
              />
              <div className="mt-2 grid grid-cols-9 gap-x-5">
                <label className="col-span-2 text-sm text-lightGray-200 font-normal lg:text-darkGray lg:font-medium">
                  Address
                </label>
                <textarea
                  className="col-span-7 w-full min-h-[20px] p-1 text-sm text-darkGray font-normal border border-lightGray-200 rounded-r-full focus:outline-none lg:text-darkGray lg:font-medium"
                  placeholder="Optional"
                  required
                  {...register("address")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button className="mt-5 px-6 py-2 uppercase text-sm font-extrabold text-darkGray bg-deepBlue rounded-full border border-gray-200 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-4 focus:ring-midBlue lg:bg-darkGray lg:text-white lg:hover:bg-white lg:hover:text-darkGray">
            request for a job
          </button>
        </div>
      </form>
    </section>
  );
};

export default ServicesForm;
