import React, { useState } from "react";
import { FiMenu, FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp, FaFacebook } from "react-icons/fa";
import navbarData from "../../data/navbar";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("Home");

  const openHandler = () => {
    setOpen(!open);
  };

  const activeClassHandler = (value) => {
    setActive(value);
    setOpen(false);
  };

  return (
    <>
      <div className="px-5 lg:px-16 py-2  border-gray-500 shadow-xl">
        <div className="flex justify-between">
          <div className="my-auto">
            <a href="/">
              <img className="w-36" src="/aceLogo.png" alt="" />
            </a>
          </div>
          <div className="my-auto">
            <div className="grid">
              <div className="flex mt-4 space-x-4 sm:justify-center md:mt-0">
                <a
                  href="tel:0212888700"
                  className="text-gray-600 hover:text-gray-900 dark:hover:text-white"
                >
                  <FiPhoneCall size={23} />
                </a>
                <a href="/#" className="text-blue-500 dark:hover:text-white">
                  <FaFacebook size={24} />
                </a>
                <a href="/#" className="text-green-700 dark:hover:text-white">
                  <FaWhatsapp size={26} />
                </a>
              </div>
              <div>
                <a
                  className="text-lg font-semibold text-black "
                  href="tel:0212888700"
                >
                  021 2888 700
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10 bg-gray-900 flex items-center justify-end">
        <div className="mr-20  gap-x-10 font-semibold text-gray-50 hidden lg:flex">
          <a href="/#home">Home</a>
          <a href="/#services">Our Services</a>
          <a href="/#about">About Us</a>
          <a href="/#contact">Contact Us</a>
        </div>
        <div className="absolute right-4 text-gray-50 lg:hidden">
          <FiMenu size={36} onClick={openHandler} />
        </div>
      </div>
      {open && (
        <div className="block p-5 w-full bg-gray-50 md:hidden">
          <ul>
            {navbarData.map((navbar, index) => (
              <li key={index}>
                <a
                  href={navbar.link}
                  onClick={() => activeClassHandler(navbar.title)}
                  className={`mt-1 px-5 py-1 block rounded text-[20px] text-gray-700 hover:bg-gray-100 ${
                    active === navbar.title ? "bg-royalyellow-700" : ""
                  }`}
                >
                  {navbar.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
