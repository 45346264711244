import React from "react";
import Layout from "../../components/layout/Layout";
import ServicesList from "./ServicesList";
import AboutUs from "./AboutUs";
import AffordablePrice from "./AffordablePrice";
import Testimonials from "./Testimonials";
import ServicesForm from "./ServicesForm";

const Home = () => {
  return (
    <Layout>
      <section id="home" className="body-x-padding mt-5 lg:hidden">
        <ServicesForm />
        <AffordablePrice />
      </section>
      <section
        className="body-x-padding py-10 h-[800px] w-full bg-no-repeat bg-center bg-cover hidden lg:block"
        style={{ backgroundImage: "url('/images/bg/home-cover.png')" }}
      >
        <div className="grid grid-cols-5">
          <div className="col-span-2 w-full">
            <ServicesForm />
          </div>
          <div className="col-span-1"> </div>
          <div className="col-span-2">
            <AffordablePrice />
          </div>
        </div>
      </section>

      <ServicesList />
      <AboutUs />
      <Testimonials />
    </Layout>
  );
};

export default Home;
