import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceItem = ({ service }) => {
  const navigate = useNavigate();

  return (
    <div
      className="service-card group service-card-white"
      onClick={() => navigate(`/services/${service.id}`)}
    >
      <img
        src={service.images[0]}
        alt=""
        className="service-image mb-5 w-[61px] h-[57px] lg:h-[130px] lg:w-[187px]"
      />
      <img
        src={service.images[1]}
        alt=""
        className="service-image mb-5 w-[61px] h-[57px] lg:h-[130px] lg:w-[187px]"
      />
      <h6 className="service-title service-title-white">{service.title}</h6>
    </div>
  );
};

export default ServiceItem;
