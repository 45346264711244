const servicesData = [
  {
    id: 1,
    title: "New Builds",
    images: [
      "/images/services/new-build/white.svg",
      "/images/services/new-build/white-hover.svg",
      "/images/services/new-build/dark.svg",
      "/images/services/new-build/dark-hover.svg",
    ],
  },
  {
    id: 2,
    title: "Rennovations",
    images: [
      "/images/services/rennovations/white-hover.svg",
      "/images/services/rennovations/white-hover.svg",
    ],
  },
  {
    id: 3,
    title: "Repairs",
    images: [
      "/images/services/repair/white.svg",
      "/images/services/repair/white-hover.svg",
    ],
  },
  {
    id: 4,
    title: "Maintenance",
    images: [
      "/images/services/maintenance/white.svg",
      "/images/services/maintenance/white-hover.svg",
    ],
  },
  {
    id: 5,
    title: "Equipments",
    images: [
      "/images/services/equipment/white.svg",
      "/images/services/equipment/white-hover.svg",
    ],
  },
  {
    id: 6,
    title: "Consultancy",
    images: [
      "/images/services/consultancy/white.svg",
      "/images/services/consultancy/white-hover.svg",
    ],
  },
];

export default servicesData;
