import React from "react";
import servicesData from "../../data/servicesData";
import ServiceItem from "./ServiceItem";

const ServicesList = () => {
  return (
    <section id="services" className="body-x-padding mt-5 lg:mt-24">
      <h1 className="text-lg font-extrabold text-darkGray text-center lg:text-3xl">
        OUR SERVICES
      </h1>
      <div className="mt-5 p-5 rounded-10px border border-lightBlue grid gap-5 grid-cols-2 lg:grid-cols-3 lg:gap-x-20 lg:mt-12">
        {servicesData.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
      </div>
    </section>
  );
};

export default ServicesList;
