const testimonialData = [
  {
    id: 1,
    name: "Tracy Stevenson Steve",
    address: "West Auckland, New Zealand",
    review:
      "We have had such a wonderful experience with ACE-Tech every time we have used their services over the past few years or more, they always go above and beyond to make sure that the job is done right and on time. The Team is honest, reliable, always on time and will always give you a fair quote. Qualities that keep us coming back!...",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKypx5PxLdo5OdQCBtF8W4YeyboFlZKuW_g&usqp=CAU",
  },
  {
    id: 2,
    name: "Tracy Stevenson don",
    address: "West Auckland, New Zealand",
    review:
      "We have had such a wonderful experience with ACE-Tech every time we have used their services over the past few years or more, they always go above and beyond to make sure that the job is done right and on time. The Team is honest, reliable, always on time and will always give you a fair quote. Qualities that keep us coming back!...",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKypx5PxLdo5OdQCBtF8W4YeyboFlZKuW_g&usqp=CAU",
  },
  {
    id: 3,
    name: "Tracy Stevenson zoe",
    address: "West Auckland, New Zealand",
    review:
      "We have had such a wonderful experience with ACE-Tech every time we have used their services over the past few years or more, they always go above and beyond to make sure that the job is done right and on time. The Team is honest, reliable, always on time and will always give you a fair quote. Qualities that keep us coming back!...",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKypx5PxLdo5OdQCBtF8W4YeyboFlZKuW_g&usqp=CAU",
  },
  {
    id: 4,
    name: "Tracy Stevenson zoe",
    address: "West Auckland, New Zealand",
    review:
      "We have had such a wonderful experience with ACE-Tech every time we have used their services over the past few years or more, they always go above and beyond to make sure that the job is done right and on time. The Team is honest, reliable, always on time and will always give you a fair quote. Qualities that keep us coming back!...",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKypx5PxLdo5OdQCBtF8W4YeyboFlZKuW_g&usqp=CAU",
  },
];

export default testimonialData;
