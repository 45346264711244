import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import servicesDetailsData from "../../data/servicesDetailsData";
import ServicesList from "../home/ServicesList";
import Hero from "./Hero";
import ServiceDetailsMobile from "./ServiceDetailsMobile";
import ServicesForm from "./ServicesForm";

const DynamicServices = () => {
  const { serviceId } = useParams();

  const singleService = servicesDetailsData.find(
    (service) => service.id.toString() === serviceId.toString()
  );

  const { shortTitle, help, descriptionTwo, descriptionThree } =
    singleService || {};

  return (
    <Layout>
      <ServiceDetailsMobile service={singleService} />
      <Hero service={singleService} />

      <div className="sm-dynamic-service-form py-5 body-x-padding lg:grid grid-cols-2 gap-x-20">
        <div className="col-span-1 hidden lg:block">
          <div className="w-full bg-[#E9F8FF] h-[92%] rounded-10px p-2">
            <div className="w-full h-full border border-white rounded-10px py-32 px-24">
              <p className="text-[16px] text-darkGray font-medium">
                {shortTitle}
              </p>
              <p className="my-5 text-[16px] text-midBlue font-medium ">
                {help}
              </p>
              <p className="text-[16px] text-darkGray font-medium">
                {descriptionTwo}
              </p>
              <p className="mt-8 text-[16px] text-darkGray font-medium">
                {descriptionThree}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <ServicesForm />
        </div>
      </div>
      <ServicesList />
    </Layout>
  );
};

export default DynamicServices;
