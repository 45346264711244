import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialData from "../../data/testimonialData";

const settings = {
  dots: true,
  infinite: true,
  speed: 3000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Testimonials = () => {
  return (
    <section id="testimonial" className="mt-5 lg:mt-12">
      <h1 className="text-lg font-extrabold text-darkGray text-center lg:text-3xl">
        TESTIMONIALS
      </h1>
      <div className="mt-5 py-8 bg-lightBlue text-center lg:mt-12">
        <div className="body-x-padding">
          <Slider {...settings}>
            {testimonialData.map((data, index) => (
              <div
                key={index}
                className="mt-8 py-10 w-full  bg-darkGray rounded-[10px] break-words text-center border-r border-gray-400 lg:mt-0"
              >
                <p className="my-5 px-2 font-poppins text-gray-50 text-sm font-normal">
                  {data.review}
                </p>
                <div className="flex justify-center mt-2">
                  <img
                    className="w-[60px] h-[60px] rounded-full"
                    src={data.image}
                    alt=""
                  />
                </div>
                <p className="my-3 font-poppins text-gray-50 text-sm font-normal">
                  {data.name}
                </p>
                <i className="my-3 font-poppins text-gray-50 text-sm font-normal">
                  {data.address}
                </i>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
