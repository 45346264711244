import React from "react";

const ServiceDetailsMobile = ({ service }) => {
  const {
    title,
    shortTitle,
    help,
    descriptionOne,
    descriptionTwo,
    descriptionThree,
    icon,
    coverImage,
  } = service || {};
  return (
    <div className="mt-5 body-x-padding lg:mt-0 lg:hidden">
      <div className="">
        <h1 className="mb-5 text-lg font-extrabold text-darkGray text-center uppercase">
          {title}
        </h1>
        <div className="py-5 border border-darkGray rounded-10px text-center">
          <div className="flex justify-center">
            <div className="h-[99px] w-[97px] bg-midBlue rounded-10px flex items-center justify-center">
              <img src={icon} alt="" className="w-[44px] h-[50px]" />
            </div>
          </div>
          <p className="px-9 mt-12 text-sm text-darkGray font-normal">
            {descriptionOne}
          </p>
          <img
            src={coverImage}
            alt={title}
            className=" my-12 w-full h-[215px]"
          />
          <p className="px-9 text-sm text-darkGray font-normal">{shortTitle}</p>
          <p className="px-9 my-5 text-sm text-midBlue font-medium ">{help}</p>
          <p className="px-9 text-sm text-darkGray font-normal">
            {descriptionTwo}
          </p>
          <p className="mt-5 px-9 text-sm text-darkGray font-normal">
            {descriptionThree}
          </p>
          <div className="mt-20 mb-5 px-5">
            <div className="px-9 pt-1.5 pb-2 block w-full relative rounded-full border border-darkGray text-left">
              <span className="-mt-1 uppercase text-[12px] text-deepBlue font-medium">
                Want to discuss ?
              </span>
              <button
                type="button"
                className="px-5 py-[7px] absolute top-0 right-0 border border-darkGray  bg-darkGray text-white rounded-r-full cursor-default"
              >
                <img className="w-5 h-[22px]" src="/icon/down.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceDetailsMobile;
